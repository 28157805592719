import Vue from 'vue'
import store from '@/store'
import Router from 'vue-router'
import Home from './views/Home.vue'
import Login from '@/views/login.vue'
import schema from '@/entity.js'

import { isLoggedIn } from '@/lib/authentication'

Vue.use(Router)

const router = new Router({
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },

    {
      path: '/login',
      name: 'login',
      component: Login
    },

    {
      path: '/event-sources',
      name: 'sources',
      component: () => import(/* webpackChunkName: "crossings" */ '@/views/event-sources')
    },

    {
      name: 'participants',
      path: '/:raceEventId/participants',
      component: () => import(/* webpackChunkName: "participants" */ './views/participants.vue'),
      props: true,
      
      children: [
        {
          name: 'participant', // this named route will not render children, but we can detect this and render the last shown tab.
          path: ':id',
          component: () => import(/* webpackChunkName: "participants" */ './views/participant-details.vue'),
          props: true,
          
          children: [
            {
              name: 'pd-performance',
              path: '',
              component: () => import(/* webpackChunkName: "participants" */ './views/participant-details/pd-performance.vue'),
            },
            {
              name: 'pd-details',
              path: 'details',
              component: () => import(/* webpackChunkName: "participants" */ './views/participant-details/pd-edit.vue'),
            },
            {
              name: 'pd-contact',
              path: 'contact',
              component: () => import(/* webpackChunkName: "participants" */ './views/participant-details/pd-contact.vue'),
            },

            {
              name: 'pd-wave',
              path: 'wave',
              component: () => import(/* webpackChunkName: "participants" */ './views/participant-details/pd-wave.vue'),
            },

            {
              name: 'pd-addons',
              path: 'addons',
              component: () => import(/* webpackChunkName: "participants" */ './views/participant-details/pd-addons.vue'),
            },
            
            {
              name: 'pd-livestream',
              path: 'livestream',
              component: () => import(/* webpackChunkName: "participants" */ './views/participant-details/pd-livestream.vue'),
            },
            
          ],
          
        },
                
      ]
    },
    
    {
      name: 'teams',
      path: '/:raceEventId/teams',
      component: () => import(/* webpackChunkName: "participants" */ './views/teams.vue'),
      props: true,
    },
    
    {
      name: 'manual',
      path: '/:raceEventId/manual',
      component: () => import(/* webpackChunkName: "participants" */ './views/manual-entry.vue'),
      props: true,
    },
    
    {
      name: 'addons',
      path: '/:raceEventId/orders',
      component: () => import(/* webpackChunkName: "participants" */ './views/addons.vue'),
      props: true,
    },
    
    {
      name: 'wavegrid',
      path: '/:raceEventId/wavegrid',
      component: () => import(/* webpackChunkName: "planning" */ './views/wave-grid.vue'),
      props: true,
    },
    
    {
      name: 'podium',
      path: '/:raceEventId/podium',
      component: () => import(/* webpackChunkName: "podium" */ './views/podiums.vue'),
      props: true,
    },
    
    {
      name: 'schedule',
      path: '/:raceEventId/schedule',
      component: () => import(/* webpackChunkName: "planning" */ './views/schedule.vue'),
      props: true,
    },
    
    {
      path: '/:raceEventId/map',
      name: 'map',
      component: () => import(/* webpackChunkName: "map" */ '@/views/map')
    },

    {
      path: '/:raceEventId/crossings',
      name: 'crossings',
      props: true,
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "crossings" */ './views/crossings.vue'),

      children: [
        {
          name: 'replay',
          path: 'replay',
          component: () => import(/* webpackChunkName: "crossings" */ './views/replay-controller.vue')
        }
      ]
    },
    
    {
      path: '/:raceEventId/stats',
      name: 'stats',
      props: true,
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "stats" */ './views/stats/stats.vue'),

      children: [
        {
          name: 'finishtimes',
          path: 'finishtimes',
          component: () => import(/* webpackChunkName: "stats" */ './views/stats/chart-finishtimes'),
        },
        {
          name: 'gender',
          path: 'gender',
          component: () => import(/* webpackChunkName: "stats" */ './views/stats/chart-gender'),
        },
        {
          name: 'shirtsizes',
          path: 'shirtsizes',
          component: () => import(/* webpackChunkName: "stats" */ './views/stats/chart-shirtsizes'),
        },
        {
          name: 'localities',
          path: 'localities',
          component: () => import(/* webpackChunkName: "stats" */ './views/stats/chart-states'),
        }
      ]
    },
    
    {
      name: 'livestream',
      path: '/:raceEventId/livestream',
      component: () => import(/* webpackChunkName: "planning" */ './views/livestream.vue'),
      props: true,
    },
    
    {
      path: '/races',
      name: 'races',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "planning" */ './views/event-sources.vue'),
    },
    
    {
      path: '/settings',
      name: 'settings',
      props: true,
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "settings" */ './views/settings.vue'),
    },
  ]
});

router.beforeEach((to, from, next) => {
  if (to.name !== 'login' && !isLoggedIn()) {
    next({ name: 'login', query: {t: to.path} })
  }
  else {
    next();
  }
});

router.afterEach((to, from, next) => {
  // This will catch URL changes, but will fail if race events aren't loaded.
  // In that case, the store will set the correct state itself upon load finish.
  //
  if (to.params.raceEventId && to.params.raceEventId != from.params.raceEventId) 
  {
    const newActiveRaceEventId = parseInt(to.params.raceEventId);
    console.log("router: Setting activeRaceEvent to ", newActiveRaceEventId);
    const raceEvent = store.getters.raceEvent(newActiveRaceEventId);

    if (!raceEvent) {
      console.warn("router: new active raceEvent null");
      
      store.dispatch('loadEntities', {
        url: `/race_events/${newActiveRaceEventId}`,
        entity: schema.raceEvent
      }).then(r=> {
        // We asked for a raceEvent by ID, so we'll set it now.
        if (undefined === r.entities.raceEvents[newActiveRaceEventId]) {
          return console.error(`Could not load raceEvent ID ${newActiveRaceEventId}`);
          // or throw up an error dialog, etc.
        }
        
        const re = r.entities.raceEvents[newActiveRaceEventId];
        store.commit('setActiveRaceEvent', re);
      });
    }
    else {
      raceEvent && store.commit('setActiveRaceEvent', raceEvent);
    }
  }
  
  // next();
  
});


export default router;
