import Vue from 'vue'
import Vuex from 'vuex'

import {reviveJSONDates} from '@/lib/date-tools'

import {normalize} from 'normalizr'
import schema from '@/entity.js'
import modelize from "@/lib/models.js"
import {stateWalkRelations} from '@/lib/state-util'

import ParticipantRanker from "@/lib/participant-ranker.js"

import router from '@/router' // for responding to route params


Vue.use(Vuex)

function getSettings() {
  // Standard localStorage methods; value is default
  const sl = {
    forceExpandedNav: false,
    printerAddress: undefined,
    expandSidebar: false,
    sidebarTab: 'notifications',
    lastSeenSelectedSplitId: undefined,
    announcerEventSource: null,
    leaderboardGender: 'M',
    speakAnnouncer: false,
    announcerTargetEventSource: undefined,
    announcerShowInvalid: false,
  }

  // Static or custom methods of initialization
  let settings = {
    wantsMiles: window.localStorage.unitPreference == 'mi',
  };

  Object.keys(sl).forEach(k => {
    const localValue = window.localStorage[k];
    if (!localValue) {
      settings[k] = sl[k]; // default value
    }
    else {
      try {
        settings[k] = JSON.parse(localValue);
      }
      catch(e) {
        // JSON parse error.
        settings[k] =sl[k];
      }
    }
  });

  return settings;
}

export default new Vuex.Store({
  state: {   
    
    loading: {}, // {stateKey: Promise} stateKey is a URL
    loadingEntities: [], // a set of string entityNames
    
    // Random debounce counter
    counter: 0,
    
    // Settings
    settings: getSettings(),
    
    // Livetrack
    livetrackLastHB: null, // a Date object
    livetrackTimer: null,  // a setTimeout handle
    lpsMonitorStatus: undefined,
    
    /* WebSocket Channels */
    controlChannelConnected: false,
    eventChannelConnected: false,
    
    /* Event Sources */
    // eventSourcesLoadingState: "loading",
    eventSources: {},
    eventRoutes: {},
    
    /* Race selection */
    raceId: 0,
    activeRaceEvent: null,
    raceEventsState: "loading",
    activeRaces: [],
    
    /* Recent history */
    recentParticipants: [],

    /* Normalized Entities */
    raceEventsOffset: 0, /* the offset parameter for loading more race events */
    raceEvents: {}, /* normalized list of race event days from server */
    races: {},      /* normalized list of races on a race event day */
    splits: {},
    participants: {}, /* normalized participants. Keep in mind these may be for different races & events */
    crossings: {},
    notifications: {}
    
    // nonreactive: 
    /*
    rankers:{
      raceId: ParticipantRanker
    }
    */
  },
  mutations: {
    
    setting(state, {name, value}) {
      switch (name) {
        case 'wantsMiles':
          window.localStorage.setItem('unitPreference', value ? 'mi' : 'km');
          break;
        default:
          console.debug(`Setting changed: ${name} -> ${value}`);
          window.localStorage.setItem(name, JSON.stringify(value));
          break;
      }

      state.settings[name] = value;
    },
    
    setActiveRaces(state, races) {
      state.activeRaces = races;
    },
    
    messageId(state, id) {
      state.id = id
    },
    
    // Add a participant to the recent participants list. The list is
    // capped at 10 participants.
    pushRecentParticipant(state, par) {
      const i = state.recentParticipants.indexOf(par);
      if (i >= 0) {
        // move to head by splicing out old index and unshifting new one.
        state.recentParticipants.splice(i, 1);
      }

      state.recentParticipants.unshift(par);

      if (state.recentParticipants.length > 10)
        state.recentParticipants.pop();
    },
    
    clearRecents(state) {
      for (var i = state.recentParticipants.length - 1; i >= 0; i--) {
        setTimeout(() => {
          state.recentParticipants.splice(i, 1);
        }, i * 50);
      }
      
    },
    
    setActiveRaceEvent(state, raceEvent) {
      console.log("Store: set setActiveRaceEvent:", raceEvent);
      localStorage.activeRaceEventId = raceEvent && raceEvent.id;
      state.activeRaceEvent = raceEvent;
    },
    
    /* Action Cable */
    setChannelState(state, payload) {
      // So cmd-f will find this
      // controlChannelConnected
      // eventChannelConnected
      //
      state[payload.channel + 'ChannelConnected'] = payload.connected
      Object.values(state.eventSources).forEach(es => es.lastMessageId = -1);
    },
    
    /* Event Source REST Resources */
    setEventSources(state, esources) {
      state.eventSources = esources.map(s => reviveJSONDates(s));
    },
    
    setEventSourceStatus(state, payload) {
      console.debug("store: setEventSourceStatus ", payload.status)
      // const es = state.eventSources.find(s => s.id == payload.adapter_id);
      const es = state.eventSources[payload.adapterId];
      if (!es) {
        console.warn(`Received event source status for ${payload.adapterId}, but did not find corresponding eventSource`);
        return;
      }
      
      console.debug("Payload:", payload);
      const transform = (k,v) => (k === 'updatedAt' ? new Date(v) : v);
      Object.keys(payload.sync || {}).forEach(k => Vue.set(es, k, transform(k, payload.sync[k])));
      
      Vue.set(es, 'status', payload.status);
      
    },
    
    setPosIngressStatus(state, payload) {
      console.debug("store: setPosIngressStatus ", payload.status)
      // const es = state.eventSources.find(s => s.id == payload.adapter_id);
      const re = state.raceEvents[payload.raceEventId];
      if (!re) {
        console.warn(`Received position ingress status for ${payload.raceEventId}, but did not find corresponding raceEvent`);
        return;
      }
      
      Vue.set(re, 'posIngressState', payload.status);
    },
    
    setLPSMonitorStatus(state, payload) {
      state.lpsMonitorStatus = payload.status;
    },
        
    /* Entity committing */
    // Args:
    //   + payload.entities must be a hash of entity names in normalized form
    //
    //   + payload.parent should be an object whos entityName key will be set
    //
    //     when the load is finished. This is needed to give a destination for
    //     empty results sets. 
    //
    //   + payload.onResolve can be a hook that gets called when this is 
    //     done committing all entities.
    //
    //   + payload.pruneIf can be a closure that will remove entities that meet
    //     a certain criteria. E.g., forget crossings older than 30 minutes.
    //
    commitEntities(state, payload) {
      
      // console.log("commitEntities: ", payload);

      const promiseResolve = payload.onResolve; // may be undefined
      const parent = payload.parent;            // may be undefined
      const pruneIf = payload.pruneIf;          // may be undefined

      if (payload.entities)
        payload = payload.entities;
      
      Object.keys(payload).forEach((entityName) => {
        Object.keys(payload[entityName]).forEach(k => {
          reviveJSONDates(payload[entityName][k]);
        });
      
        // Add the key if it didn't already exist, making it reactive.
        if (!state[entityName])
          Vue.set(state, entityName, {});
      
        // Insert all entities into the store
        // This needs to be done in such a way as to be reactive.
        // We're essentially doing Object.assign(state[entityName], payload[entityName]),
        // but calling Vue.set along the way.
        // 
        Object.keys(payload[entityName]).forEach((id) => {
          let obj = payload[entityName][id];
          
          // standardize on integer IDs where they're not ephemeral strings
          // @TODO: Should we actually standardize on just expecting string IDs?
          if (id.indexOf && id.indexOf('NEW') < 0)
            id = parseInt(id);
          
          // modelize(this, entityName, obj);
          
          // If the object already exists in the store, update individual keys
          // instead of obliterating the whole object.
          // Also try to find an ephemeral-keyed object to update with a new ID
          // instead of duplicating it on the round-trip back.
          //
          let stateObj = undefined;
          if (obj.ephemeralKey && (stateObj = state[entityName][obj.ephemeralKey])) {
            console.debug(`result ephemeralKey: ${obj.ephemeralKey}; storeObj:`, state[entityName][obj.ephemeralKey]);
            // We have state.myThings.NEW-32452352 = {...}
            // - We need to move that object to state.myThings[obj.id]
            // - And update all relations.
            stateObj = Object.assign({id}, stateObj);
            Vue.set(state[entityName], id, stateObj);
            
            // @TODO: I *think* we can rip out the code from destroyEntity and pass a
            // closure to it that will walk the relations and either splice (for destroy) 
            // or change ID (for this)
            //
            // The below does kind of that by deleting the ephemeral object that was successfully
            // created and then letting the code below update (poorly) its relations
            Vue.delete(state[entityName], obj.ephemeralKey);
            // we have no context here context.commit('destroyEntity', {
            //   entitySchema: schema[schema.singularize(entityName)],
            //   id: obj.ephemeralKey
            // });
            
          }
          else {
            stateObj = state[entityName][id];
          }
          if (stateObj) {
            Object.keys(obj).forEach(key => {
              //console.debug("store: update key for stateObj",stateObj, key, obj[key]);
              Vue.set(stateObj, key, obj[key]);
            });
          }
          else {
            // Safe to quickly insert the whole object
            stateObj = Vue.set(state[entityName], id, obj);
          }
          
          // Update each related object to point to this object
          // e.g. if this is a participant, add it to `races[this.raceId].participants`
          //
          const entitySchema = schema[schema.singularize(entityName)];
//          console.groupCollapsed(`Walking relations of ${schema.singularize(entityName)}[${id}]`);
          for (const relation of stateWalkRelations(state, entitySchema, id)) {
            switch (relation.type) {
              case 'belongsTo': {
                if (!relation.owner) { 
                  // The object doesn't exist yet. Stub it with the relation so that it
                  // will have something to merge with later.
                  let stub = {id: relation.objectId};
                  stub[relation.key] = [stateObj.id];
                  // console.log(`=> Creating state.${relation.name}[${relation.objectId}] with id: ${relation.objectId}`);

                  if (relation.objectId !== undefined)
                    Vue.set(state[relation.name], relation.objectId, stub);
                }
                else {
                  // The object exists and it holds an array of references to 
                  // stateObj-like objects
                  //
                  if (!relation.owner[relation.key]) {
                    // console.warn(`=> Creating ${relation.name}[${relation.owner.id}].${relation.key}[${id}]`);
                    Vue.set(relation.owner, relation.key, [id]);
                  }
                  else
                  if (relation.owner[relation.key].indexOf(id) < 0) {
                    // push stateObj into `relation.name`'s `relation.key`
                    // e.g. 
                    //     {name: "raceEvents", type: "belongsTo", owner: {…}, key: "races", objectId: 2833}
                    // 
                    if (typeof(id) == 'string') {
                      // This happens when the ID is e.g. NEW-123456.
                      // I don't know what we want to do differently here.
                      //
                      
                      //relation.owner[relation.key].push(id);
                    }

                    if (!relation.owner[relation.key])
                      Vue.set(relation.owner, relation.key, []);  // theRaceEvent.races ||= []

                    // console.log(`=> pushing ${relation.name}[${relation.owner.id}].${relation.key} << ${id} (${typeof(id)})`);
                    relation.owner[relation.key].push(id);
                  }
                }
                break;
              }
            
              case 'hasMany': {
                // console.debug(`hasMany relation on ${entitySchema._key}: `, relation);
                // console.debug(`hasMany: ${entitySchema._key}.${relation.name}`, stateObj[relation.name]);
                if (stateObj[relation.name]) {
                  for (const relatedObjectId of stateObj[relation.name]) {
                    // Update this relatedObject's reflected relation attribute to point at stateObj
                    let relatedObject = state[relation.name][relatedObjectId];

                    if (!relatedObject) {
                      // Create a stub.
                      relatedObject = state[relation.name][relatedObjectId] = {
                        id: relatedObjectId,
                      }
                    }

                    relatedObject[relation.attribute] = id;
                  }
                }
               break;
              }
            
              case 'hasOne': {
                console.debug(`hasOne relation on ${entitySchema.key}: `, relation);
                debugger;
                break;
              }
            }
          }
//          console.groupEnd();
          
        }); // each id in payload
      
        // Update relations if available.
        if (parent && Object.values(payload[entityName]).length < 1) {
          // No entity relationships will be updated for this result, which will
          // cause the request to be resent in an infinite loop. We break this loop
          // by correctly setting the relation key on `payload.parent` to an empty array.
          if (!parent[entityName]) {
            // e.g. race.participants = []
            Vue.set(parent, entityName, payload[entityName]);
          }
        }
        
        // Prune objects that meet a certain criteria
        //
        if (pruneIf) {
          for (const obj of Object.keys(payload[entityName])) {
            console.log
          }
        }
      });//each key in payload
      
      if (promiseResolve)
        promiseResolve();
    },
    
    // Remove entity from store. The dispatch version of this
    // goes round-trip to the back end to synchonize the database
    // and then calls this if it's successful.
    //
    destroyEntity(state, {entitySchema, id}) {      
      const root = state[entitySchema._key][id];

      // If root is undefined, we probably won't find anything to do. 
      // But let's look anyway.
      // if (!root) return;
      //
      for (const relation of stateWalkRelations(state, entitySchema, id)) {
        
        // Delete the relation
        // state[relation][relatedObjectId][relationIdKey] = undefined;
        
        console.debug(`destroyEntity: got relation: `, relation);

        switch (relation.type) {
        case 'belongsTo': {
          // the entity we are destroying belongs_to a relative that has_many 
          // of our entities. We need to splice ourselves out of this array.
          const list = relation.owner[relation.key];
          const i = list.indexOf(id);
          console.debug(`destroyEntity: belongsTo -> object.${relation.key}.splice(${i}, 1)`);
          console.debug('=> was', list);
          list.splice(i, 1);
          console.debug('=> now', list);
          break;
        }

        case 'hasMany': {
          // participant has_many crossings: 
          //    + relation.name == 'crossings'
          //    + relatedObjectId == a related crossing's ID.
          //
          console.log(`destroyEntity: hasMany on ${entitySchema.key}.${relation.name}`);
          if (root[relation.name]) {
            root[relation.name].forEach(relatedObjectId => {
              console.debug(`destroyEntity: hasMany -> ${entitySchema._key}.${relation.name}[${relatedObjectId}].${relation.attribute} = null`);
              state[relation.name][relatedObjectId][relation.attribute] = undefined;
            });
          }
          break;
        }

        case 'hasOne':
          console.debug(`destroyEntity: hasOne -> relatedObject[${relation.key}] = undefined`);
          relation.relatedObject[relation.key] = undefined;
          break;

        default:
          console.warn('unknown relation type', relation.type);
          break;
        }
      }
      
      // Delete the original object
      Vue.delete(state[entitySchema.key], id);
    },
    
    // Set status = loading, etc.
    // @TODO: Consolidate on a universal loading API
    setRaceEventsState(state, status) {
      state.raceEventsState = status
    },
    
    setLoadingStatus(state, {entityName, isLoading}) {
      if (isLoading) {
        if (state.loadingEntities.indexOf(entityName) < 0)
          state.loadingEntities.push(entityName);
      }
      else {
        let i;
        console.log(`state.loadingEntities.indexOf('${entityName}')=`, state.loadingEntities.indexOf(entityName))
        while ((i=state.loadingEntities.indexOf(entityName)) >= 0) {
          state.loadingEntities.splice(i,1);
        }
        console.debug(`=> done loading now: ${state.loadingEntities.join(', ')}`, );
      }
    },
    
    livetrackHeartbeat(state, hb) {
      state.livetrackLastHB = hb;
    },
    
    clearNotifications(state) {
      Vue.set(state, 'notifications', {});
    }
  },
  actions: {
    setEventSourceStatus(context, payload) {
      // Ignore out-of-order status changes; we only want the most recent one.
      // If we don't get a message ID (why?), assume it's valid so as to 
      // not lose data.
      const eventSource = context.getters.entity('eventSources', payload.adapterId);
      if (!eventSource) return console.error("No event source for payload", payload);
      const messageId = payload.msgId;
      
      // Using messageIds
      if (messageId) {
        const lastMessageId = eventSource.lastMessageId || -1;
        if (messageId <= lastMessageId) {
          console.warn("Ignoring out-of-order status change", payload);
          return
        }
        
        // Technically should be an additional commit.
        let normalized = {};
        eventSource.lastMessageId = messageId;
        normalized[eventSource.id] = eventSource;
        context.commit('commitEntities', { entities: { eventSources: normalized} });
      }
      
      context.commit('setEventSourceStatus', payload);
    },
    
    setPosIngressStatus(context, payload) {
      const raceEvent = context.state.raceEvents[payload.raceEventId];
      if (!raceEvent) return console.error("No raceEvent for payload", payload);
      
      const messageId = payload.msgId;
      
      if (messageId) {
        const lastMessageId = raceEvent.posIngressStatusAt || -1;
        if (messageId <= lastMessageId) {
          return console.warn("Ignoring out-of-order pos status change", payload);
        }
      }
      
      let normalized = {}
      normalized[raceEvent.id] = {posIngressState: payload.status, posIngressStatusAt: messageId}
      context.commit('commitEntities', { entities: { raceEvents: normalized } });
    },
    
    // Catch race events loading status and react to changes.
    // This might assume that it only happens at initial page load.
    //
    setRaceEventsState(context, status) {
      console.debug(`store: setRaceEventsState to ${status}`);
      if (status === 'loaded' && !context.state.activeRaceEvent) {
        
        const trySetActiveRaceEvent = context => {
          // Try to set based on the route, if there is one.
          const id = context.getters.activeRaceEventId;
          if (id && !isNaN(id)) {
            const raceEvent = context.getters.raceEvent(id);
            raceEvent && context.commit('setActiveRaceEvent', raceEvent);
          }
        };
        
        trySetActiveRaceEvent(context);
        console.debug("store.commit setRaceEventsState", status);
      }

      context.commit('setRaceEventsState', status);
    },

    /* Get / Load */
    loadEventSources(context) {
      return context.dispatch('loadEntities', {
        url: 'event_sources',
        entity: [schema.eventSource]
      });
    },
    
    loadRaceEvents(context, opts) {
      let url = 'race_events';
      
      if (opts && opts.usingOffset) {
        let offset = context.state.raceEventsOffset;
        if (offset > 0)
          url += `?o=${offset}`
      }
      
      // need to increment offset now
            
      return new Promise((resolve, reject) => {
        context.dispatch('setRaceEventsState', 'loading');
        context.dispatch('loadEntities', {
          url,
          entity: [schema.raceEvent]
        })
        .catch(r=> reject(r))
        .then(() => {
          context.dispatch('setRaceEventsState', 'loaded');
          resolve();
        });
      });
    },
    
    loadDetailAttrs(context, {url, entity, attributes}) {
      console.debug("Load detail attributes", attributes);
      url += '?a=';
      url += attributes.join('+');
      
      // Getting unexpected results? Are you loading a single
      // entity or an array of [entity] ?
      
      return context.dispatch('loadEntities', {url, entity});
    },
    
    loadEntities(context, {url, entity, parent}) {
      const entityName = entity.constructor == Array ? entity[0].key : entity.key;
      
      let promise = context.state.loading[url];
      if (promise) {
        console.warn("store: Already loading %s; skipping", entityName, promise);
        return promise;
      }

      context.commit('setLoadingStatus', {entityName, isLoading: true })
      
      promise = new Promise((resolve, reject) => {
        console.debug(`⬇️ Loading "${entityName}" (${url})`);
        // load what we've been asked for.
        this.$axios.get(url)
        .catch((err) => {
          console.error(`Load error for ${entityName}`, err);
          delete context.state.loading[url];
          return reject(err);
        })
        .then((response) => {
          if (!response || !response.data) {
            delete context.state.loading[url];
            return reject("Bad response");
          }
                    
          let data = normalize(response.data, entity);

          // Pass along the parent object to commitEntities
          data.parent = parent;
    
          // Handle case of empty response (response.data == [] )
          // We have a 200-series OK response here, so assume we're getting empty content
          // This way we create the correct key in the store instead of the application
          //  perpetually requesting a load for a nonexistant resource.
          //
          if (Object.keys(data.entities) < 1) {
            data.entities[entityName] = []
          }

          // Track offsets
          let offsetOfThisResponse = parseInt(response.headers['index-offset']) || 0;
          let countOfThisResponse = Object.keys(data.entities[entityName]).length;
          let newOffset = offsetOfThisResponse + countOfThisResponse;
          if (context.state[entityName + 'Offset'] < newOffset) {
            context.state[entityName + 'Offset'] = newOffset;
           // console.log(`%cNext Offset of ${entityName} is now ${context.state[entityName + 'Offset']} (received offset ${offsetOfThisResponse} and ${countOfThisResponse} entities)`, "color:red")
          }

          context.commit('commitEntities', data);
          Vue.delete(context.state.loading, url);

          context.commit('setLoadingStatus', {entityName, isLoading: false});

          delete context.state.loading[url];
          resolve(data);
        });
      });
      
      context.state.loading[url] = promise;
      return promise;
    },
    
    setActiveRaceEvent(context, activeRaceEvent) {
      // We really only want the active selected race to affect the single client that's on.
      // Crossing events will be corelated by date, not the 'active' event
      //
      context.commit('setActiveRaceEvent', activeRaceEvent);

      if (router.currentRoute.params.activeRaceEvent) {
        console.log("dispatch setActiveRaceEvent: currentRoute", router.currentRoute, context.state.activeRaceEvent.id);
        let route = {
          name: router.currentRoute.name,
          query: router.currentRoute.query,
          params:  Object.assign({
            activeRaceEvent: context.state.activeRaceEvent.id
          }, router.currentRoute.params),
        };

        Vue.nextTick(() => {
          console.warn("store: will push route ", route);
          router.push(route);
        });
      }
    },
    

    /* Create / Insert */

    
    upsertParticipantsChunk(context, payload) {
      
      return new Promise((resolve, reject) => {
        if (!context.state.activeRaceEvent) {
          // This should generally never happen. In practice, it's because import was clicked
          // before the activeRaceEvent could be set. 
          console.error("Attempt to upload participants without an active race event");
          reject();
          return;
        }
      
        // Participants is an array of objects. We need to send these to the server for trusted 
        // validation of the keys, then upsert them into the state here. 
        //
        // These may come with addons like a headlamp that packet pickup will need.
        //
        this.$axios.post(`/race_events/${context.state.activeRaceEvent.id}/participants/import`, payload )
        .then((response) => {
          // At this point we should have received all the participants through the websocket.
          console.log("Finished uploading", response);
          console.warn("upsertParticipantsChunk resolve");
          resolve(response.data);
        });

        // console.warn("upsertParticipantsChunk resolve");
        // resolve();
      })
    },
    
    upsertBulkCrossings(context, {crossings, eventSourceId, timezoneOffset, fileName}) {
      
      return new Promise((resolve, reject) => {
        if (!context.state.activeRaceEvent) {
          // This should generally never happen. In practice, it's because import was clicked
          // before the activeRaceEvent could be set. 
          console.error("Attempt to upload participants without an active race event");
          reject();
          return;
        }
      
        // `crossings` is an array of objects with a bib and time. We need to send these to the 
        // server for trusted validation of the keys, then upsert them into the state here. 
        //
        this.$axios.post(`/race_events/${context.state.activeRaceEvent.id}/crossings/import`, { eventSourceId, crossings, timezoneOffset, fileName } )
        .then((response) => {
          // At this point we should have received all the participants through the websocket.
          console.log("Finished uploading", response);
          console.warn("upsertBulkCrossings resolve");
          window.location.reload();
          resolve(response.data);
        });
      });
    },
    
    // Receive information about a crossing. 
    // 
    // 1. We only save the last 100 crossings.
    //    (If you need more crossings shown, you need to cache them in your component)
    //
    // 2. Crossings are stored normalized, but **relations are not hooked up**
    //    Participants, Races, etc will not have a giant crossings: [1,3,4...] array.
    //
    receiveCrossing(context, data) {
      context.commit('commitCrossing', data);
    },
    
    commitEntities(context, data) {
      return new Promise((resolve, reject) => {
        Vue.nextTick(() => {
          data.onResolve = resolve;
          context.commit('commitEntities', data);
        })
      });
    },
    

    /* Update */


    saveRace(context, race) {
      let method, path;
      
      if (race.id) {
        path = `race_events/${race.raceEventId}/races/${race.id}`
        method = 'put';
      }
      else {
        // If the race has a raceEventId then use it, but it probably won't, so use 
        // the currently selected active race.
        const raceEventId = race.raceEventId || context.state.activeRaceEvent.id;
        path = `race_events/${raceEventId}/races`;
        method = 'post';
      }
      
      // Remove participants id array
      let payload = Object.assign({}, race);
      delete payload.participants;
      
      // Send request
      let promise = this.$axios.request({
        method,
        url: path,
        data: {
          race: payload
        }
      });

      
      return promise.then((response) => {
        // Save result. 
        const data = normalize(response.data, schema.race);
        //console.debug("got updated normalized data", data);
        context.commit('commitEntities', data);
      })
    },
    
    // Save an entity, either creating it or putting changes.
    // If the entity has an ID, we will append that ID to the path and use a PUT.
    // If the entity does not have an ID, the path is used as is and we use POST.
    //
    saveEntity(context, {path, entity, object}) {
      const en = entity;
      if (!en) return console.error(`Entity is undefined for ${path}`);

      let promise;
      let create = false;
      let payload = {};
      
      if (object) {
        // Move ephemeral IDs for newly-created objects
        // These are objects with an ID containing, e.g., NEW-123467
        // This changes {id: 'NEW-1234567'} to {ephemeralKey: 'NEW-1234567'}
        //
        const moveEphemeralForObject = o => {
          let v;
          Object.keys(o).forEach(k => {
            v = o[k];
            if (v === undefined || v === null) return;
            if (k == 'id' && (typeof v === 'string') && v.includes('NEW-')) {
              // Make sure that the object is in the store so that we can
              // catch it when the save completes with the same ephemeral key
              this.state[entity.key][v] = o;
              // Swap id for ephemeralKey
              o.ephemeralKey = v;
              delete o.id;
              return;
            }
          
            // Recurse deeply.
            if (typeof v === 'object') return moveEphemeralForObject(v);
          });
        };
        
        moveEphemeralForObject(object);

        // Set up params like {race: {...}}
        const singularName = schema.singularize(entity.key)
        payload[singularName] = object;
      }
      
      console.debug("store: saveEntity", entity.name);
      
      if (object.id) {
        path += `/${object.id}`;
        promise = this.$axios.put(path, payload);
      }
      else {
        create = true;
        promise = this.$axios.post(path, payload);
      }
      
      return promise.then((response) => {
        // Save result. 
        const data = normalize(response.data, entity);
        //console.debug("store.saveEntity: updated normalized data", data);
        context.commit('commitEntities', data);
        response.isCreateResponse = create;
        return response;
      });
    },
    
    updatePlacement(context, payload) {
      context.commit('commitPlacement', payload);
    },
    
    reassignCrossings(context, payload) {
      // This is kind of a hack. Assume that the crossing belongs to the 
      // current RaceEvent. I don't know why it wouldn't at this point.
      // 
      const raceEvent = context.state.activeRaceEvent;

      const promises = payload.crossings.map(c => {
        return context.dispatch('saveEntity', {
          path: `race_events/${raceEvent.id}/crossings`,
          entity: schema.crossing,
          object: {
            id: c.id,
            participantId: payload.receiver.id
          }
        });
      });
      
      return new Promise((resolve, err) => {
        Promise.all(promises).then(resolve).catch(err)
      });
    },
    
    
    /* Destroy */
    
    
    destroyEventSource(context, eventSource) {
      if (!eventSource || !eventSource.id)
        return console.warn("cannot destroy eventSource without id", eventSource);
      
      const path = `event_sources/${eventSource.id}`;
      console.warn("delete", path);
      
      this.$axios.delete(path).then((result) => {
        context.commit('destroyEntity', {entity: 'eventSources', id: eventSource.id});
      })
    },
    
    destroyRace(context, race) {
      if (!race || !race.id)
        return console.warn("cannot destroy race without id", race);

      const raceEventId = race.raceEventId;
      const path = `race_events/${raceEventId}/races/${race.id}`;
      console.warn("delete race", path);
      
      return new Promise((resolve, reject) => {
        this.$axios.delete(path).then((result) => {
          context.commit('destroyEntity', {entity: 'races', id: race.id});
          resolve(race);
        })
        .catch((err) => reject(err));
      })
    },
    
    destroyEntity(context, {entity, entitySchema, path}) {

      // Check args
      if (!entity || !entity.id) {
        console.warn("Attempt to destroy null entity");
        debugger
        return
      }
      
      if (!entitySchema) 
        return console.error("Cannot destroy entity without schema", entity);
      
      /******* DEBUG ******* /
      console.warn("Delete entity (DEBUG)", entitySchema._key, entity);
      return new Promise((resolve) => { 
        context.commit('destroyEntity', {entitySchema, id: entity.id});
        resolve(); 
      });
      /******* DEBUG *******/
      
      if (entity.id.includes && entity.id.includes('NEW-')) {
        // This isn't on the server, so don't go there.
        context.commit('destroyEntity', {
          entitySchema: entitySchema,
          id: entity.id
        });
        
        return new Promise((resolve) => { resolve(); });
      }
      
      return new Promise((resolve, reject) => {
        this.$axios.delete(path).then((result) => {
          context.commit('destroyEntity', {entitySchema: entitySchema, id: entity.id});
          resolve(entity);
        })
        .catch((err) => reject(err));
      });
    },
    
    livetrackStatus(context, status) {
      if (window.livetrackTimer) {
        console.debug("Livetrack heartbeat: cancel timer");
        clearTimeout(window.livetrackTimer);
      }
      
      window.livetrackTimer = setTimeout(() => {
        console.warn("Livetrack heartbeat timeout")
        context.commit('livetrackHeartbeat', null);
      }, 15000);
      
      if (status == 'OK') {
        context.commit('livetrackHeartbeat', new Date());
      }
      else {
        context.commit('livetrackHeartbeat', null);
      }
    },
    
    clearNotifications(context) {
      // in the future it might make sense to post the current_user
      // to mark which notifications have been seen by whom. For now
      // we just let one viewer nuke them for all future viewers
      //
      // returns a Promise
      return this.$axios.post('/notifications/read_all').then(r=> {
        context.commit('clearNotifications');
      });
    }
    
  },
  
  getters: {
    // Getters can return a function which will be called with 
    // any passed arguments. This is what's going on with the
    // double arrow syntax.
    
    activeRaceEventId(state) {
      const route = router.currentRoute;

      // Prefer the state of the store. 
      if (state.activeRaceEvent) {
        console.debug("Store: ActiveRaceId from store", state.activeRaceEvent.id);
        return state.activeRaceEvent.id;
      }

      else
      if (route && route.params && route.params.raceEventId) {
        console.debug("Store: ActiveRaceId from route");
        return route.params.raceEventId;
      }
      else
      if (window.localStorage.activeRaceEventId && parseInt(window.localStorage.activeRaceEventId)) {
        console.debug("Store: ActiveRaceId from localStorage");
        return window.localStorage.activeRaceEventId;
      }
      
      console.debug("Store: ActiveRaceId undefined");
      return undefined;
    },
    
    randomID(state) {
      return function() {
        // return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
        return `NEW-${window.performance.now() * 1000000000000}${state.counter++}`;
      }
    },
    
    raceEvent: state => (raceEventIds) => {
      if (!raceEventIds) return [];
      let single = false;
      if (typeof raceEventIds == "string") {
        raceEventIds = parseInt(raceEventIds);
      }
      
      if (typeof raceEventIds == 'number')  {
        single = true;
        raceEventIds = [raceEventIds]
      }
      const results = raceEventIds.map((id) => state.raceEvents[id]);
      return single ? results.length && results[0] || undefined : results;
    },
    
    races: state => (raceIds)  => {
      let single = false;
      if (!raceIds) return [];
      if (typeof raceIds == 'number')  {
        single = true
        raceIds = [raceIds]
      }
        
      const results = raceIds.map((id) => state.races[id]);
      return single ? results.length && results[0] || undefined : results;
    },
    
    activeRaces: (state, getters) => {
      return (state.activeRaceEvent && state.activeRaceEvent.races && getters.races(state.activeRaceEvent.races)) || [];
    },
    
    entity: state => (entityName, ids) => {
      if (!entityName) return undefined;
      if (!ids) return [];
      let single = false;

      // if (entityName == 'eventSources')
      //   debugger;

      if (typeof ids == "string") {
        ids = parseInt(ids);
        single = true;
      }
      
      if (typeof ids == 'number')  {
        single = true;
        ids = [ids]
      }
      
      // Reject undefined IDs
      ids = ids.filter(id=>id);
      
      const results = ids.map((id) => state[entityName][id]);
      return single ? 
        results.length ?
          results[0] :
          undefined :
        results;
    },
    
    participants: state => participantIds => {
      if (!participantIds) return [];
      if (typeof participantIds == 'number') participantIds = [participantIds];
      return participantIds.map(id => state.participants[id])
    },
    
    participantByBib: state => (bibNumber, raceEvent) => {
      if (!bibNumber) return null;
      if (typeof bibNumber != "string") bibNumber = bibNumber.toString();
      
      let raceIds = raceEvent ? raceEvent.races : false;
      return Object.values(state.participants).find(par => {
        // Limit to Race Event if given
        return (raceIds === false || raceIds.includes(par.raceId)) && par.bib == bibNumber
      });
    },
    
    sortedCrossings: state => {
      console.log("Store: calculating sortedCrossings");
      return Object.values(state.crossings).sort((a,b) => b.timestamp - a.timestamp)
    },
    
    rankerForRace: (state, getters) => race => {
      if (!race) return null;
      const raceId = typeof race === 'number' ? race : race.id;
      if (!raceId) return null;
      if (!state.rankers) state.rankers = {};
      let ranker = state.rankers[raceId];
      if (!ranker) {
        let participants = Object.values(state.participants).filter(par=>par.raceId==raceId);
        ranker = state.rankers[raceId] = new ParticipantRanker(state.races[raceId], participants);
      }

      return ranker;
    }

  }
  
})
