<template>
  <li class="race-event-result" @click="handleClick" :style="rowStyle">
    <figure class="icon">
      <img :src="raceEventImageUrl" alt="race logo" @error="raceEventImageError">
    </figure>
    <span class="name">{{ subject.name }}</span>
  </li>
</template>

<style lang="scss">
.race-event-result {
  display: flex;
  flex-direction: row;
  line-height: 44px;
  cursor: pointer;
  user-select: none;
  
  background-image: linear-gradient(to right, var(--accent-color) -150%, var(--alternate-row-bgcolor) 100%); /* Set the background image with a gradient */
	background-repeat: no-repeat;
  background-size: calc(100% - 100% * var(--s)) 100%; /* Set the background size to 50% of the width and 100% of the height */
	
  &:hover {
    background: var(--row-hover-bgcolor);
  }

  &>span {
    vertical-align: middle;
    vertical-align: -webkit-baseline-middle;
    white-space: nowrap; text-overflow: ellipsis; overflow: hidden
  }
 
  span:not(last-child) {
    margin-right: 8px;
  }
  
  figure.icon {
    width: 50px;
    text-align: center;
		margin: 0 8px 0 0;
		padding: 0;
		
		img {
			max-width: 50px;
			max-height: 40px;
			vertical-align: middle;
		}
  }
  
  .name { 
		font-weight: bold;
		min-width: 10ex; 
		overflow: hidden; 
		text-overflow: ellipsis; 
		white-space:nowrap; 
	}

}
</style>

<script>

import aravaipaLogo from '@/assets/img/aravaipa-logo.png'

export default {
  props: {
    subject: {
      type: Object,
      required: true
    },
		
    score: {
      type: Number,
      required: false,
			default: 0,
    }
  },
  
  emits: ['click', 'resultClick'],
  
  computed: {
		rowStyle() {
			return `--s: ${this.score}`;
		},
    raceEventImageUrl() {
			const raceEvent = this.subject;
			
      if (raceEvent && raceEvent.avatarSmUrl) {
        return raceEvent.avatarSmUrl;
      }
      else if (raceEvent && raceEvent.avatarUrl) {
        return raceEvent.avatarUrl;
      }
      else {
        return aravaipaLogo;
      }
    },
  },
  
  methods: {
    raceEventImageError(e)  {
      
    },
    
    handleClick() {
      this.$emit('resultClick', {type: 'raceEvent', subject: this.subject});
    }
  }
}
</script>